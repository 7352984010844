export const LOGIN_API = 'auth/login';
export const CHANGE_PASSWORD_API = 'user/change-password';
export const FORGOT_PASSWORD_API = 'auth/forgot-password';

// users
export const USERS_LIST_API = 'user/list';
export const CANDIDATE_LIST_API = 'user/user-list';
export const CANDIDATE_DELETE_API = 'user/delete-user';
export const USER_DETAILS_API = 'user/details';
export const USER_STATUS_API = 'user/disable-user';
export const USER_DELETE_API = 'user/delete';
export const USER_PRODUCTS_LIST_API = 'product/user-products';

//Feeds
export const FEED_LIST = 'feed/feed-list';

///Recuriter////
export const RECURITER_LIST_API = 'recuriter/recuriter-list'
export const RECURITER_DELETE_API ='recuriter/delete-recuriter'
export const CLIENT_ADD_API = 'recuriter/add-client'
export const RECURITER_STATUS_API = 'recuriter/disable-recruiter'
export const CLIENT_DETAIL_API = 'recuriter/get-company'
export const EDIT_CLIENT_DETAIL = 'recuriter/edit-client'
export const UPLOAD_COMAPANY_LOGO = 'recuriter/upload-company-logo'
export const COMPANY_STATUS_API = 'recuriter/disable-company'

// decks
export const DECK_LIST_API = 'deck/list';
export const CREATE_DECK_API = 'deck/add';
export const UPDATE_DECK_API = 'deck/update';
export const DECK_IMAGE_API = 'deck/image-upload';
export const DELETE_IMAGE_API = 'deck/image-delete'
export const DECK_DETAILS_API = 'deck/details';
export const DECK_DELETE_API = 'deck/delete';
export const DECK_STATUS_API = 'deck/status';
// decks cards
export const CARD_LIST_API = 'deck/card-list';
export const NORMAL_CARD_LIST_API = 'deck/normal-card-list';
export const CREATE_CARD_API = 'deck/add-card';
export const UPDATE_CARD_API = 'deck/edit-card';
export const CARD_DETAILS_API = 'deck/card-details';
export const CARD_DELETE_API = 'deck/delete-card';

//game points

export const POINT_LIST_API = 'game-point/list';
export const UPDATE_POINT_API = 'game-point/update';
export const ADD_POINT_API = 'game-point/add';
export const DELETE_POINT_API = 'game-point/delete';

//game time
export const TIME_LIST_API = 'game-time/list';
export const UPDATE_TIME_API = 'game-time/update';
export const ADD_TIME_API = 'game-time/add';
export const DELETE_TIME_API = 'game-time/delete';

//categories
export const CATEGORY_LIST_API='category/list'
export const CATEGORY_CHANGE_STATUS_API='category/change-status'
export const CATEGORY_DELETE_API = 'category/delete';
export const CATEGORY_DETAILS_API = 'category/details';
export const UPDATE_CATEGORY_API ='category/edit'
export const ADD_CATEGORY_API ='category/save'
export const SUBCATEGORY_LIST_API ='sub-category/list'
export const SUBCATEGORY_DELETE_API = 'sub-category/delete';
export const SUBCATEGORY_CHANGE_STATUS_API='sub-category/change-status'  
export const SECTION_CHANGE_STATUS_API='section/change-status'
export const SECTION_DELETE_API = 'section/delete';
export const UPDATE_SUBCATEGORY_API ='sub-category/edit'
export const ADD_SUBCATEGORY_API ='sub-category/save'
export const UPDATE_SECTION_API ='section/edit'
export const ADD_SECTION_API ='section/save'
export const SECTION_DETAILS_API = 'section/details';
export const SUBCATEGORY_DETAILS_API = 'sub-category/details';
export const SECTIONS_LIST_API ='section/list'
export const ARTICLE_LIST_API ='article/list'
export const ARTICLE_ADD_API ='article/save'
export const ARTICLE_EDIT_API ='article/edit'
export const ARTICLE_CHANGE_STATUS_API ='article/change-status'
export const ARTICLE_DELETE_API ='article/delete'
export const BRAND_LIST_API ='brand/list'
export const BRAND_ADD_API ='brand/save'
export const BRAND_EDIT_API ='brand/edit'
export const BRAND_CHANGE_STATUS_API ='brand/change-status'
export const BRAND_DELETE_API ='brand/delete'

//Product
export const PRODUCT_LIST_API='product/list'
export const PRODUCT_DETAIL_API = 'product/details';
export const PRODUCT_CHANGE_STATUS_API = 'product/change-status';
export const PRODUCT_CHANGE_PRIORITY = 'product/change-priority';
export const PRODUCT_APPROVE_REJECT_API = 'product/approve-reject';