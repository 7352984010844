<div class="login-container bg-gradient">
  <div class="logo_class">
    <img src="../../../assets/images/logo.png" height="80px;"/>
  </div>
    <div class="login-box mat-elevation-z8">
      <!-- <h1><span class="txt-primary">Log</span> <span class="txt-accent">In</span></h1> -->
      <h1>Let's Sign You In</h1>
      <form [formGroup]="loginForm" [style.opacity]="isLoading?'0.4':'1'">
        <mat-form-field appearance="outline">
          <mat-label>Email : </mat-label>
          <input type="email" matInput formControlName="email" placeholder="email" required>
          <mat-icon matSuffix>mail</mat-icon>
          <mat-error> {{loginForm.controls.email.errors|validateError:'Email '}} </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Password : </mat-label>
          <!-- <mat-icon matPrefix>lock_outline</mat-icon> -->
          <input [type]="hide?'password':'text'" matInput formControlName="password" placeholder="enter password" required>
          <mat-icon matSuffix (click)="hide = !hide">
            {{ hide ? "visibility" : "visibility_off" }}
          </mat-icon>
          <mat-error> {{loginForm.controls.password.errors|validateError:'Password '}} </mat-error>
        </mat-form-field>
        <!-- <br> -->
        <!-- <div class="row">
          <span class="forget_class">Forget Password?</span>
        </div> -->
        
        <button type="submit" mat-raised-button color="primary" (click)="login()" style="padding: 6px;">
          CONTINUE
          <img src="assets/images/loginarrow.png" class="img-fluid pull-right" alt=""/>
        </button>

        <!-- <div class="row">
          <span class="bottom_class">Or, Sign Up<span style="padding-left: 4px;"><strong>Using Email</strong></span></span>
        </div> -->
      </form>
  
      <div class="loader" *ngIf="isLoading">
        <mat-spinner color="accent" [strokeWidth]="5"></mat-spinner>
      </div>
    </div>
  </div> 

   <!-- <button type="button" mat-button routerLink="{{forgotPasswordRoute.url}}" class="txt-accent">Forgot Password?</button> -->
  