import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CANDIDATE_LIST_API, RECURITER_DELETE_API, RECURITER_LIST_API,RECURITER_STATUS_API, CLIENT_ADD_API, CLIENT_DETAIL_API, EDIT_CLIENT_DETAIL, UPLOAD_COMAPANY_LOGO, COMPANY_STATUS_API } from 'src/app/constants/api.constants';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class RecuriterService {
  sort:string;

  constructor(private $http:HttpService) { }

  // getRecuriterList(){
  //   return this.$http.get(CANDIDATE_LIST_API)
  // }


  getRecuriterList(
    page: number = 1,
    limit: number,
    sort: string,
    search?: string,
    fields?: string,
    startDate?:any,
    endDate?:any
    ): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());
    params = params.set('sort', sort);
    params = params.set('fields', fields);
    if(startDate && endDate){
      params = params.set('startDate', startDate);
      params = params.set('endDate', endDate);
    }
    if (search) {
      params = params.set('search', search);
    }
    return this.$http.get(RECURITER_LIST_API, params);
  }
  
  // getUserDetails(_id: string): Observable<{
  //   status: number,
  //   data: {
  //     user: Candidate,
  //     addedProductCount:number
  //   }
  // }> {
  //   return this.$http.get(`${USER_DETAILS_API}/${_id}`);
  // }

  // getUserProductsList(
  //   _id: string,
  //   page: number = 1,
  //   limit: number,
  //   sort: string,
  //   search?: string): Observable<
  // {
  //   status: number,
  //   message: string,
  //   data: {
  //     count: number;
  //     list: []
  //   }
  // }
  // >{
  //   let params = new HttpParams();
  //   params = params.set('page', page.toString());
  //   params = params.set('limit', limit.toString());
  //   params = params.set('sort', sort);
  //   if (search) {
  //     params = params.set('search', search);
  //   }
  //   return this.$http.get(`${USER_PRODUCTS_LIST_API}/${_id}`, params);
  // }

  deleteUser(_id: string): Observable<{
    status: number,
    message: string,
  }> {
    return this.$http.delete(`${RECURITER_DELETE_API}/${_id}`);
  }

  changeRecruiterStatus(_id: string): Observable<
    {
      message: string,
      data: { user: any }
    }
  > {
    return this.$http.get(`${RECURITER_STATUS_API}/${_id}`, {});
  }

  getEmployeeList(
    page: number = 1,
    limit: number,
    sort: string,
    ): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());
    params = params.set('sort', sort);
    return this.$http.get(RECURITER_LIST_API, params);
  }

  addClient(data:any) {
    return this.$http.post(CLIENT_ADD_API, data)
  }

  getClientDetail(id:any){
    return this.$http.get(`${CLIENT_DETAIL_API}/${id}`);
  }

  editClientDetail(data:any, id:any) {
    return this.$http.put(`${EDIT_CLIENT_DETAIL}/${id}`, data);
  }

  uploadBannerfile(data:any){
    return this.$http.post(`${UPLOAD_COMAPANY_LOGO}`, data);
  }

  changeCompanyStatus(_id: string): Observable<
    {
      message: string,
      data: { user: any }
    }
  > {
    return this.$http.get(`${COMPANY_STATUS_API}/${_id}`, {});
  }


  
}
