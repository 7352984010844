<div class="confirm-container">
  <h4>{{ data.message }}</h4>
  <p *ngIf="data?.message2">{{ data?.message2 }}</p>
  <div class="buttons">
    <button
      mat-stroked-button
      class="txt-success"
      (click)="onConfirm()"
      style="height: 42px"
    >
      {{ data.yBtnTxt }}
    </button>
    <button
    mat-stroked-button
    class="txt-danger"
    style="height: 42px"
    (click)="onCancel()"
  >
    {{ data.nBtnTxt }}
  </button>
  </div>
</div>

<!-- <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <i class="material-icons">&#xE5CD;</i>
        </div>            
        <h4 class="modal-title w-100">Are you sure?</h4>  
     
      </div>
      <div class="modal-body">
        <p>Do you really want to delete these records?</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="onConfirm()">Delete</button>
      </div>
    </div>
  </div> -->

<!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> -->
