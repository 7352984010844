import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CANDIDATE_DELETE_API, CANDIDATE_LIST_API, USER_STATUS_API } from 'src/app/constants/api.constants';
import { Candidate } from 'src/app/modals/candidate.modal';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidateListingService {

  constructor(private $http:HttpService, private http:HttpClient) { }
  pageNumber:number;
  limitNumber:number;

  // getCandidateList(data){
  //   return this.$http.get(CANDIDATE_LIST_API, data);
  // }

  getCandidateList(
    page: number = 1,
    limit: number,
    sort: string,
    search?: string,
    fields?: string,
    startDate?:any,
    endDate?:any
    ): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());
    params = params.set('sort', sort);
    params = params.set('fields', fields);
    if(startDate && endDate){
      params = params.set('startDate', startDate);
      params = params.set('endDate', endDate);
    }
    if (search) {
      params = params.set('search', search);
    }
    return this.$http.get(CANDIDATE_LIST_API, params);
  }
  
  // getUserDetails(_id: string): Observable<{
  //   status: number,
  //   data: {
  //     user: Candidate,
  //     addedProductCount:number
  //   }
  // }> {
  //   return this.$http.get(`${USER_DETAILS_API}/${_id}`);
  // }

  // getUserProductsList(
  //   _id: string,
  //   page: number = 1,
  //   limit: number,
  //   sort: string,
  //   search?: string): Observable<
  // {
  //   status: number,
  //   message: string,
  //   data: {
  //     count: number;
  //     list: []
  //   }
  // }
  // >{
  //   let params = new HttpParams();
  //   params = params.set('page', page.toString());
  //   params = params.set('limit', limit.toString());
  //   params = params.set('sort', sort);
  //   if (search) {
  //     params = params.set('search', search);
  //   }
  //   return this.$http.get(`${USER_PRODUCTS_LIST_API}/${_id}`, params);
  // }

  deleteUser(_id: string): Observable<{
    status: number,
    message: string,
    data: { user: Candidate }
  }> {
    return this.$http.delete(`${CANDIDATE_DELETE_API}/${_id}`);
  }

  changeUserStatus(_id: string): Observable<
    {
      message: string,
      data: { user: any }
    }
  > {
    return this.$http.get(`${USER_STATUS_API}/${_id}`, {});
  }


  getExperiencedetails(id:any){
    return this.$http.get(`user/user-profile/${id}`)
  }


  editdetails(data:any, id:any){
    return this.$http.post(`user/edit-profile/${id}`, data);
  }


  bulkUpload(data:any) {
    // return this.$http.post(`user/candidate-bulk-upload`, data)
    return this.http.post(`${environment.apiUrl}user/candidate-bulk-upload`, data , {
        reportProgress: true,
        observe: "events"
    });
  }


  

}





interface CandidateResp {
  status: number;
  data: {
    totalCount: number;
    records: Candidate[]
  }
}




