import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CHANGE_PASSWORD_ROUTE, DASHBOARD_ROUTE, DECK_ROUTE, LOGIN_ROUTE, POINT_ROUTE, PROPERTY_ROUTE, TIME_ROUTE, USER_ROUTE,CATEGORY_ROUTE, BRAND_ROUTE, PRODUCT_ROUTE, CANDIDATE_LIST, RECURITER_LIST, FEED_LIST, CAMPAIGN_ROUTE, REPORT_LIST, SUBSCRIPTION_ROUTE } from '../constants/route.constants';
import { DialogService } from '../modules/dialog/service/dialog.service';
import { REPORT_ROUTE } from '../pages/reports/constants/route.constant';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  showSubSubMenu: boolean = false;
  dashboardRoute = DASHBOARD_ROUTE;
  userRoute = USER_ROUTE;
  categoryRoute = CATEGORY_ROUTE;
  changePasswordRoute = CHANGE_PASSWORD_ROUTE;
  deckRoute =  DECK_ROUTE;
  pointsRoute = POINT_ROUTE;
  timeRoute = TIME_ROUTE;
  brandRoute = BRAND_ROUTE
  productRoute = PRODUCT_ROUTE;
  candidateRoute = CANDIDATE_LIST;
  feedRoute = FEED_LIST;
  reportRoute = REPORT_LIST;
  RecuriterRoute = RECURITER_LIST; 
  campaignRoute = CAMPAIGN_ROUTE;
  subscription = SUBSCRIPTION_ROUTE
  isShowing:boolean = true;
  mode = 'side';
  opened = true;
  supportActiveClass:boolean = false;
  isLoading = false;
  isHandset = new BehaviorSubject<boolean>(false);
  @ViewChild('drawer') drawer: MatDrawer;
  constructor(
    private $loader: LoadingService,
    private $router: Router,
    private $dialogService: DialogService,
    private $activatedRoute: ActivatedRoute
  ) {
    if (window.innerWidth < 980) {
      this.isHandset.next(true);
    }
    fromEvent(window, 'resize').subscribe(data => {
      if (window.innerWidth < 980) {
        this.isHandset.next(true);
      } else {
        this.isHandset.next(false);
      }
    });
  }

  ngOnInit(): void {
    
  }

  mouseenter() {
   // this.isShowing = true;
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }
  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  ngAfterViewInit(): void {
    this.isHandset
      .pipe(
        delay(0)
      )
      .subscribe((data) => {
        if (data) {
          this.mode = 'over';
          this.opened = false;
        } else {
          this.mode = 'side';
          this.opened = true;
        }
      });
  }

  onClickRoomzLogo(): void {
    if (this.mode === 'over') {
      this.drawer.toggle();
    }
  }

  toggleSidenav(){
    this.isShowing = !this.isShowing;
  }


  logOut(): void {
    this.$dialogService.confirm(
      status => {
        if (status) {
          localStorage.removeItem('adminAccessToken');
          this.$router.navigateByUrl(LOGIN_ROUTE.url);
        }
      },
      'Are you sure, you want to sign out?'
    );
  }

}
