import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginComponent } from "./components/login/login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import {
  BRAND_ROUTE,
  CAMPAIGN_ROUTE,
  CANDIDATE_LIST,
  CATEGORY_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  DASHBOARD_ROUTE,
  DECK_ROUTE,
  FEED_LIST,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  POINT_ROUTE,
  PRODUCT_ROUTE,
  PROPERTY_ROUTE,
  RECURITER_LIST,
  REPORT_LIST,
  SUBSCRIPTION_ROUTE,
  TIME_ROUTE,
  USER_ROUTE,

} from "./constants/route.constants";
import { ContainerComponent } from "./container/container.component";
import { AuthGuard } from "./guards/auth.guard";
import { ProtectGuard } from "./guards/protect.guard";

const routes: Routes = [
  {
    path: "",
    component: ContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: DASHBOARD_ROUTE.path,
        pathMatch: "full",
      },
      {
        path: DASHBOARD_ROUTE.path,
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: USER_ROUTE.path,
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: CATEGORY_ROUTE.path,
        loadChildren: () =>
          import("./pages/categories/categories.module").then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: BRAND_ROUTE.path,
        loadChildren: () =>
          import("./pages/brand/brand.module").then((m) => m.BrandModule),
      },
      {
        path: PRODUCT_ROUTE.path,
        loadChildren: () =>
          import("./pages/listing/listing.module").then((m) => m.ListingModule),
      },
      {
        path: CANDIDATE_LIST.path,
        loadChildren: () =>
          import("./pages/candiate/candiate.module").then(
            (m) => m.CandiateModule
          ),
      },
      {
        path:FEED_LIST.path,
        loadChildren:() => 
        import("./pages/feed/feed-management.module").then(
          (m) => m.FeedManagementModule
        )
      },
      {
        path:REPORT_LIST.path,
        loadChildren:() => import("./pages/reports/reports.module").then(
          (m) => m.ReportsModule
        )
      },
      {
        path:'Support',
        loadChildren:() => import("./pages/support-management/support-management.module").then((m)=>m.SupportManagementModule)
      },
      {
        path:SUBSCRIPTION_ROUTE.path,
        loadChildren: () => import("./pages/subscription/subscription.module").then((m) => m.SubscriptionModule)
      },
      {
        path: RECURITER_LIST.path,
        loadChildren: () =>
          import("./pages/recuriter/recuriter.module").then(
            (m) => m.RecuriterModule
          ),
      },
     

      {
        path: CHANGE_PASSWORD_ROUTE.path,
        component: ChangePasswordComponent,
      },
      {
        path: CAMPAIGN_ROUTE.path,
        loadChildren: () =>
        import("./pages/campaign/campaign.module").then(
          (m) => m.CampaignModule
        ),
      },
    ],
  },
  {
    path: LOGIN_ROUTE.path,
    component: LoginComponent,
    canActivate: [ProtectGuard],
  },
  {
    path: FORGOT_PASSWORD_ROUTE.path,
    component: ForgotPasswordComponent,
    canActivate: [ProtectGuard],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash:true
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
