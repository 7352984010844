export const LOGIN_ROUTE = {
  path: 'login',
  get url(): string {
    return `/${this.path}`;
  }
};

export const REPORT_LIST = {
  path:'report',
  get url():string {
    return `/${this.path}`
  }
}


export const FORGOT_PASSWORD_ROUTE = {
  path: 'forgot-password',
  get url(): string {
    return `/${this.path}`;
  }
};

export const CHANGE_PASSWORD_ROUTE = {
  path: 'change-password',
  get url(): string {
    return `/${this.path}`;
  }
};

export const DASHBOARD_ROUTE = {
  path: 'dashboard',
  get url(): string {
    return `/${this.path}`;
  }
};

export const SUBSCRIPTION_ROUTE = {
  path:'subscription',
  get url(): string {
    return `/${this.path}`
  }
};

export const USER_ROUTE = {
  path: 'users',
  get url(): string {
    return `/${this.path}`;
  }
};

export const CATEGORY_ROUTE = {
  path: 'category',
  get url(): string {
    return `/${this.path}`;
  }
};

export const BRAND_ROUTE = {
  path: 'brand',
  get url(): string {
    return `/${this.path}`
  }
};

export const PRODUCT_ROUTE = {
  path: 'product',
  get url(): string {
    return `/${this.path}`
  }
};

export const CANDIDATE_LIST = {
  path: 'candidate-listing',
  get url(): string {
    return `/${this.path}`;
  }
};

export const FEED_LIST = {
  path: 'feed-listing',
  get url(): string {
    return `/${this.path}`;
  }
};


export const RECURITER_LIST = {
  path: 'Recuriter-listing',
  get url(): string {
    return `/${this.path}`;
  }
};

export const PROPERTY_ROUTE = {
  path: 'properties',
  get url(): string {
    return `/${this.path}`;
  }
};

export const REPORT_ROUTE = {
  path: 'report-management',
  get url(): string {
    return `/${this.path}`;
  }
};


export const DECK_ROUTE = {
  path: 'decks',
  get url(): string {
    return `/${this.path}`;
  }
};

export const TIME_ROUTE = {
  path: 'times',
  get url(): string {
    return `/${this.path}`;
  }
};
export const POINT_ROUTE = {
  path: 'points',
  get url(): string {
    return `/${this.path}`;
  }
};

export const CAMPAIGN_ROUTE = {
  path: 'campaigns',
  get url(): string {
    return `/${this.path}`;
  }
};




