import { Component, OnInit, Inject } from '@angular/core';
import { RecuriterService } from '../../../../pages/recuriter/Service/recuriter.service';
import { AlertService } from 'src/app/modules/alert/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateListingService } from 'src/app/pages/candiate/service/candidate-listing.service';

@Component({
  selector: 'app-disable',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.scss']
})
export class DisableComponent implements OnInit {
  sentence:string;
  subSentence:string;

  constructor(
    public dialogRef: MatDialogRef<DisableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private alert:AlertService, private recuriterService:RecuriterService, private candidateService: CandidateListingService
  ) { }

  ngOnInit(): void {
    if (this.data.status == false) {
      this.sentence = `Are you sure, you want to enable ${this.data?.name}?`;
      this.subSentence = 'Note - This user will be able to access the system'
    } else {
      this.sentence = `Are you sure, you want to disable ${this.data?.name}?`;
      this.subSentence = 'Note - This user will no longer be able to access the system'
    }
  }

  changeStatus(){
    if (this.data.type === 'EMPLOYEE') {
      this.recuriterService.changeRecruiterStatus(this.data.id).subscribe((res:any) => {
       if(res){
        this.alert.success('Status successfully changed');
        this.closedDisable();
       }
     })
    } else if(this.data.type === 'COMPANY') {
      this.recuriterService.changeCompanyStatus(this.data.id).subscribe((data:any) => {
        this.alert.success('Status successfully changed');
        this.closedDisable();
      });
    } else if(this.data.type === 'CANDIDATE') {
      this.candidateService.changeUserStatus(this.data.id).subscribe((data:any) => {
        this.alert.success('Status successfully changed');
        this.closedDisable();
      });
    }
 }

 closedDisable(){
   this.dialogRef.close();
 }

}
