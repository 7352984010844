<div class="modal-body">
    <div class="modals-disable-body-wrap" *ngIf="this.data.status == false">
    <h4>
      Are you sure, you want to enable <br> {{data?.name}}?
    </h4>
    <p style="text-align:center;">Note - This user will be able to access the system</p>
    </div>
    <div class="modals-disable-body-wrap" *ngIf="this.data.status == true">
      <h4>
        Are you sure, you want to disable <br> {{data?.name}}?
      </h4>
      <p style="text-align:center;">Note - This user will no longer be able to access the system</p>
      </div>
    <div class="modal-footer">

      <button type="button" class="btn"
      (click)="closedDisable()"
      >Close</button>
      <button type="button" class="btn btn-secondary"
      (click)="changeStatus()"
      >OK</button>
     
    </div>
  </div>
